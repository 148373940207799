const amplitude = {
    identify(appName) {
        window.amplitude.setUserId(appName);
    },
    updateUserProps(props) {
        const identify = new window.amplitude.Identify();

        Object.keys(props).forEach(key => {
            identify.set(key, props[key])
        });

        window.amplitude.getInstance().identify(identify);
    },
    incrementUserProp(propName, count) {
        const identify = new window.amplitude.Identify();

        identify.add(propName, count);

        window.amplitude.getInstance().identify(identify);
    },
    event(eventName, props) {
        window.amplitude.getInstance().logEvent(eventName, props);
    },
};

const pushToDataLayer = (data) => {
    try {
        dataLayer.push(data);
    } catch (e) {
        // Do nothing. GTM is not loaded
    }
};

export {
    amplitude,
    pushToDataLayer,
};
